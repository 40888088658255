<template>
  <div class="jump">
    <!-- <p style="24px">
      正在跳转到支付页面，请稍候~
    </p> -->
    <img v-if="!qrCodeVisible && !info" class="loading" src="@/assets/images/loading.gif" alt="" />

    <div class="header" v-if="info && sys != 'pc'">
      <div class="info">
        <div class="title">商品名称</div>
        <div class="name">{{ info.subject }}</div>
      </div>
      <div>
        <svg t="1712462347171" class="icon" viewBox="0 0 1024 1024" version="1.1">
          <path
            d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m-242.432 190.976h484.096a33.792 33.792 0 0 1 33.536 33.792A32.256 32.256 0 0 1 753.664 256H269.568a33.536 33.536 0 0 1-33.536-32.768 32.512 32.512 0 0 1 33.536-32.256z m530.432 607.488a33.536 33.536 0 0 1-33.536 33.536H258.304a33.536 33.536 0 0 1-33.536-33.536v-222.72a102.4 102.4 0 0 0 22.528 1.536h11.008a20.992 20.992 0 0 0 9.728-1.536h1.536a5.632 5.632 0 0 0 4.864-1.536l17.664-4.864v163.328h440.832v-163.328l17.408 4.864a5.632 5.632 0 0 1 4.864 1.536h1.536c3.328 0 6.4 1.536 9.728 1.536h11.264a108.8 108.8 0 0 0 22.272-1.536z m-24.064-253.184a89.088 89.088 0 0 1-88.064-88.064 88.32 88.32 0 0 1-176.384 0 88.064 88.064 0 0 1-176.128 0 88.32 88.32 0 0 1-176.384 0l62.464-166.656h581.888l60.928 166.656a89.088 89.088 0 0 1-88.32 88.064z"
            :fill="payType == 'wxPay' ? '#07c160' : '#409EFF'" p-id="6073"></path>
        </svg>
      </div>
    </div>
    <div class="payBody" v-if="info && sys != 'pc'">
      <div class="payBox">
        <div class="tip">实付金额</div>
        <div class="payAmount">
          <span>￥</span>
          {{ parseFloat(info.amount / 100).toFixed(2) }}
        </div>
      </div>
      <div class="order">
        <div class="orderInfo">
          <div class="label">订单编号</div>
          <div class="content">{{ info.mchOrderNo || "" }}</div>
        </div>
        <div class="orderInfo" v-if="info.body">
          <div class="label">商品描述</div>
          <div class="content">{{ info.body }}</div>
        </div>
        <div class="orderInfo">
          <div class="label">订单时间</div>
          <div class="content">{{ $filters.parseTime(info.createdAt) }}</div>
        </div>
      </div>
      <div class="payButton" :style="{ backgroundColor: payType == 'wxPay' ? '#07c160' : '#409EFF' }" @click="onTap">
        确认付款
      </div>
    </div>

    <div class="qrCode" v-if="qrCodeVisible && sys == 'pc'">
      <img ref="qrcode" :src="qrCodeDataUrl" alt="QR Code" />
      <!-- <div class="order_money">￥{{ form.amount }}</div>
      <img ref="qrcode" :src="qrCodeDataUrl" alt="QR Code" />
      <el-divider></el-divider>
      <div class="order_desc">
        <div class="order_desc_item">
          <div class="label">订单名称</div>
          <div class="content">{{ form.subject }}</div>
        </div>
        <div class="order_desc_item">
          <div class="label">商户订单号</div>
          <div class="content">{{ form.mchOrderNo }}</div>
        </div>
        <div class="order_desc_item">
          <div class="label">创建时间</div>
          <div class="content">
            {{ $filters.parseTime(form.mchOrderNo) }}
          </div>
        </div>
      </div> -->
      <div class="scan">
        <svg class="icon" viewBox="0 0 1289 1024">
          <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
            d="M928.016126 543.908618 95.983874 543.908618c-17.717453 0-31.994625-14.277171-31.994625-31.994625S78.26642 479.919368 95.983874 479.919368l832.032253 0c17.717453 0 31.994625 14.277171 31.994625 31.994625S945.73358 543.908618 928.016126 543.908618z"
            p-id="4298"></path>
          <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
            d="M832.032253 928.016126 639.892491 928.016126c-17.717453 0-31.994625-14.277171-31.994625-31.994625s14.277171-31.994625 31.994625-31.994625l191.967747 0c17.717453 0 31.994625-14.277171 31.994625-31.994625l0-159.973123c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 159.973123C928.016126 884.840585 884.840585 928.016126 832.032253 928.016126z"
            p-id="4299"></path>
          <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
            d="M351.94087 928.016126l-159.973123 0c-52.980346 0-95.983874-43.003528-95.983874-95.983874l0-159.973123c0-17.717453 14.277171-31.994625 31.994625-31.994625S159.973123 654.341676 159.973123 672.05913l0 159.973123c0 17.717453 14.449185 31.994625 31.994625 31.994625l159.973123 0c17.717453 0 31.994625 14.277171 31.994625 31.994625C383.935495 913.738955 369.658324 928.016126 351.94087 928.016126z"
            p-id="4300"></path>
          <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
            d="M127.978498 383.935495c-17.717453 0-31.994625-14.277171-31.994625-31.994625l0-159.973123c0-52.980346 43.003528-95.983874 95.983874-95.983874l159.973123 0c17.717453 0 31.994625 14.277171 31.994625 31.994625S369.658324 159.973123 351.94087 159.973123l-159.973123 0c-17.545439 0-31.994625 14.449185-31.994625 31.994625l0 159.973123C159.973123 369.658324 145.695952 383.935495 127.978498 383.935495z"
            p-id="4301"></path>
          <path :fill="payType == 'wx' ? '#07c160' : '#409EFF'"
            d="M896.021502 383.935495c-17.717453 0-31.994625-14.277171-31.994625-31.994625l0-159.973123c0-17.545439-14.277171-31.994625-31.994625-31.994625L639.892491 159.973123c-17.717453 0-31.994625-14.277171-31.994625-31.994625s14.277171-31.994625 31.994625-31.994625l191.967747 0c52.980346 0 95.983874 43.003528 95.983874 95.983874l0 159.973123C928.016126 369.658324 913.738955 383.935495 896.021502 383.935495z"
            p-id="4302"></path>
        </svg>
        <div>
          请使用{{ payType == "wx" ? "微信" : "支付宝" }}扫一扫<br />扫描二维码完成支付
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRedirectUrl, cacheQuery } from "@/api/api";
import config from "@/config";
import wayCode from "@/utils/wayCode";
import QRCode from "qrcode";
let countdown = 5 * 60;
let queryCount = 0;
import { Message } from 'element-ui';
// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
export default {
  components: {},

  data() {
    return {
      qrCodeDataUrl: null, payType: "wx", qrCodeVisible: !1,
      info: null,
      sys: null,
      payUrl: '',
      wayCode: null,
    };
  },
  async mounted() {
    console.log("正在跳转", this.$router.params);
    const that = this;
    this.getOrderInfo();
    getRedirectUrl({})
      .then(async (res) => {
        const payUrl = res.payData || res.payUrl
        this.payUrl = payUrl
        this.sys = wayCode.getPayWay().wayCode
        this.wayCode = res.wayCode
        // 判断是否是手机/或者电脑
        if (wayCode.getPayWay().wayCode == 'pc' || wayCode.getPayWay().wayCode == 'mobile') {
          const dataUrl = await QRCode.toDataURL(payUrl, {
            margin: 2, // 设置白边大小
          });
          this.qrCodeDataUrl = dataUrl;
          // 不是移动设备就显示二维码
          if (wayCode.getPayWay().wayCode != 'mobile') {
            this.qrCodeVisible = !0;
          }
          this.payType = res.wayCode.indexOf('WX') > -1 ? 'wx' : 'ali'
          //  是支付宝支付
          if (this.payType == 'ali') {
            // 不在支付宝环境
            if (!this.isAlipayApp()) {
              // 没有alipay的时候
              if (payUrl.indexOf('alipays://') == -1) {
                this.getAliPay(payUrl)
              } else {
                location.href = payUrl;
              }
            } else {
              location.href = payUrl;
            }
            return
          }
          if (res.wayCode.indexOf('LITE') > -1 || res.wayCode.indexOf('CHANNEL') > -1) {
            location.href = payUrl;
            return
          }
        } else {
          // 在支付宝/微信环境内
          if (!this.isAlipayApp()) {
            location.href = payUrl;
          } else {
            if (payUrl.indexOf('alipays://') == -1) {
              if (res.wayCode.indexOf('FUIOU') > -1 || res.wayCode.indexOf('YSE_ALI_QR_CODE_CHANNEL') > -1) {
                const alipayurl = "alipays://platformapi/startapp?saId=10000007&qrcode=" +
                  encodeURIComponent(payUrl);
                AlipayJSBridge.call('pushWindow', {
                  url: alipayurl,
                });
              } else {
                // this.getAliPay(payUrl)
                location.href = payUrl;
              }
            } else {
              AlipayJSBridge.call('pushWindow', {
                url: payUrl,
              });
            }
          }
        }
      })
      .catch((res) => {
        console.log(res)
        if (res.code == 101) { return }
        that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } });
        // return this.$message.error(res.msg);
      });

  },
  methods: {
    getOrderInfo() {
      cacheQuery({}).then(res => {
        this.info = res;
        if (res.state == 2) {
          // this.$message.success("当前订单已支付");
          Message.success("订单支付成功");
          setTimeout(() => {
            if (res.returnUrl&&res.returnUrl.indexOf('isOthersPay=1')==-1) {
              window.location.href = res.returnUrl;
            }
          }, 1000);
        } else {
          queryCount++;
          if (queryCount < 60) {
            setTimeout(() => {
              this.getOrderInfo();
            }, 1000);
          }
        }
      })
    },
    getAliPay(url) {
      var ua = navigator.userAgent.toLowerCase();
      let alipayurl =
        "alipays://platformapi/startapp?appId=20000067&url=" +
        encodeURIComponent(url);
      if (ua.match(/baidu/i) == "baidu") {
        // 百度系使用官方方式拉起
        let jumpurl =
          "https://ds.alipay.com/?scheme=" + encodeURIComponent(alipayurl);
        window.location.href(jumpurl);
      } else {
        // let jumpurl = `https://ds.alipay.com/?scheme=${encodeURIComponent(alipayurl)}`;
        window.location.href = alipayurl;
      }
    },
    isAlipayApp() {
      var userAgent = navigator.userAgent;
      return /AlipayClient/.test(userAgent);
    },
    onTap() {
      cacheQuery({}).then(res => {
        if (res.state == 2) {
          Message.success("当前订单已支付");
            if (res.returnUrl&&res.returnUrl.indexOf('isOthersPay=1')==-1) {
              window.location.href = res.returnUrl;
            }
        } else {
          if (!this.isAlipayApp()) {
            if (this.payUrl.indexOf('alipays://') == -1) {
              this.getAliPay(this.payUrl)
            } else {
              location.href = this.payUrl;
            }
          } else {
            if (this.payUrl.indexOf('alipays://') == -1) {
              if (this.wayCode.indexOf('FUIOU') > -1) {
                const alipayurl = "alipays://platformapi/startapp?saId=10000007&qrcode=" +
                encodeURIComponent(this.payUrl);
                AlipayJSBridge.call('pushWindow', {
                  url: alipayurl,
                });
              } else {
                // this.getAliPay(payUrl)
                location.href = this.payUrl;
              }
            } else {
              AlipayJSBridge.call('pushWindow', {
                url: this.payUrl
              });
            }
          }
        }
      })
    },

  }
};
</script>
<style lang="scss" scoped>
.jump {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  background-color: #efefef;

  .loading {
    width: 30px;
    height: 30px;
    margin: 0 auto;
  }
}

.qrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: auto;

  .order_money {
    font-size: 2em;
  }

  img {
    width: 200px;
  }

  .order_desc {
    width: 100%;

    .order_desc_item {
      width: 100%;
      display: flex;
      font-size: 1em;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .scan {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 24px;

    .icon {
      margin-right: 10px;
      width: 40px;
      height: 40px;
    }
  }
}


.payBox {
  /* padding: 0 30px; */
  width: 100%;
  text-align: left;
  /* text-align: center; */
  border-bottom: solid 1px #e5e5e5;
}

.payAmount {
  /* margin-bottom: 10px; */
  font-size: 36px;
}

.payAmount span {
  font-size: 20px;
}

.tip {
  font-size: 12px;
}

.btn {
  margin: 300px auto 0;
  width: 184px;
  padding: 8px 24px;
  background-color: #07c160;
  color: white;
  font-weight: bold;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  padding: 20px 20px 30px;
}

.info {
  flex: 1;
}

.title {
  font-size: 12px;
  color: #666;
}

.name {
  font-size: 16px;
  color: #333;
}

.icon {
  width: 40px;
  height: 40px;
}

.payBody {
  flex: 1;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.order {
  margin-top: 20px;
}

.orderInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 12px;
  margin-bottom: 4px;
}

.payButton {
  width: 80%;
  padding: 8px 24px;
  background-color: #07c160;
  color: white;
  text-align: center;
  font-weight: bold;
  position: absolute;
  bottom: 20px;
  font-size: 20px;
  left: 10%;
}
</style>
